export const ValidChannels = {
  // ToDo: Please update this to be sourced from: /api/creative/getSupportedTypes
  CreativeTypes: {
    RESPONSIVE_SEARCH_AD: [1],
    EXPANDED_DYNAMIC_SEARCH_AD: [1],
    EXPANDED_TEXT_AD: [1],
    CALL_ONLY_AD: [1],
    IMAGE_AD: [2, 3, 5, 11, 14, 15, 16, 29, 30],
    HTML_DISPLAY_AD: [11, 14, 15, 16],
    SINGLE_VIDEO_AD: [2, 5, 18, 29, 30], // double check this
    AUDIO_AD: [32],
    CAROUSEL_AD: [2],
    PROMOTED_POST: [2],
    DYNAMIC_PRODUCT_AD: [2],
    COLLECTION_AD: [2],
    SLIDESHOW_AD: [2],
    MEDIA_DATA_AD: [2],
    MULTI_ASSET_RESPONSIVE_DISPLAY_AD: [3],
    DYNAMIC_HTML5_AD: [3],
    WAZE_SEARCH_AD: [9],
    PIN_AD: [9],
    TAKEOVER_AD: [9],
    YOUTUBE_VIDEO_AD: [10],
    LOCAL_AD: [19],
    PERFORMANCE_MAX: [22],
    SMART_CAMPAIGN_AD: [20],
    DISCOVERY_MULTI_ASSET_AD: [24],
    DISCOVERY_VIDEO_RESPONSIVE_AD: [24],
    DISCOVERY_CAROUSEL_AD: [24],
    PINTEREST_REGULAR: [25],
    PINTEREST_CAROUSEL: [25],
    PINTEREST_STORY: [25],
    PINTEREST_VIDEO: [25],
    PINTEREST_MAX_VIDEO: [25],
    PINTEREST_SHOPPING: [25],
    PINTEREST_SHOP_THE_PIN: [25]
  },
  BidStrategies: {
    MANUAL_CPC: [1, 3, 4, 5, 25],
    MANUAL_CPM: [3, 5, 29, 30, 32],
    MAXIMIZE_CONVERSION_VALUE: [1, 4, 10, 22, 24],
    MAXIMIZE_CONVERSIONS: [1, 3, 3.5, 4, 10, 22, 23, 24],
    TARGET_CPA: [1, 3, 3.5, 4, 10],
    TARGET_ROAS: [1, 3, 3.5, 4],
    TARGET_SPEND: [1, 3, 4, 24],
    LOWEST_COST: [2],
    LOWEST_COST_WITH_BID_CAP: [2],
    TARGET_IMPRESSION_SHARE: [1],
    COST_CAP: [2],
    PORTFOLIO: [1, 3, 4],
    MANUAL_CPV: [10],
    TARGET_CPV: [10],
    TARGET_CPM: [10],
    MANUAL_CPA: [23]
  },
  /* removed these, they are redundant to campaign tabs
  CreateCampaignValidChannels: {
    'campaign-start-and-end-dates': [1, 2, 3, 4, 9, 10, 11, 14, 15, 16, 17, 18],
    'bid-strategies': [1, 2, 3, 4, 9, 10, 19, 22, 24],
    'manage-campaign-audiences': [1, 3, 4, 9, 10]
  },
   */
  CampaignTabs: {
    basic: [1, 2, 3, 4, 5, 9, 10, 11, 14, 15, 16, 17, 18, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
    dv360: [31],
    inventory: [4],
    network: [1, 3, 4, 10, 23, 24],
    'negative-keywords': [1, 3, 4, 10, 24],
    locations: [1, 2, 3, 4, 5, 10, 11, 14, 15, 16, 17, 18, 22, 23, 24, 25, 29, 30, 32],
    languages: [1, 2, 3, 4, 10, 22, 24, 29, 30, 32],
    bidding: [1, 2, 3, 4, 5, 10, 22, 23, 24, 25, 29, 30, 32],
    'start-and-end-dates': [1, 2, 3, 4, 5, 9, 10, 11, 14, 15, 16, 17, 18, 22, 23, 24, 25, 29, 30, 32],
    'ad-scheduling': [1, 3, 4, 9, 22, 23, 24, 29, 30, 32],
    'campaign-recommendations': [1],
    targets: [1, 3, 4, 5, 9, 10],
    targetingAndAudiences: [29, 30, 32],
    userlists: [1, 3, 4, 5, 9, 10, 29, 30, 32],
    'rtb-configurations': [14, 16, 18],
    trackers: [11, 14, 15, 16],
    phoneRouting: [1, 3, 22, 24, 25],
    attributes: [1, 2, 3, 4, 5, 9, 10, 11, 14, 15, 16, 17, 18, 22, 24, 25, 29, 30, 32],
    domainLists: [29],
    services: [23],
    conversions: [29, 30, 32],
    videoConfig: [30]
  },
  AdGroupTabs: {
    basic: [1, 2, 3, 4, 5, 9, 10, 11, 14, 15, 16, 17, 18, 22, 24, 25, 26, 27, 28, 29, 30, 31, 32],
    // dv360: [31],
    negativeKeywords: [1, 3, 4, 10],
    productGroups: [4, 25],
    targets: [1, 3, 4, 5, 9, 10, 22, 24, 25],
    userlists: [1, 3, 5, 10, 22, 24, 25],
    audience: [2],
    placement: [2],
    locations: [2, 29, 30, 32],
    startAndEndDates: [2, 5, 25],
    targetingAndAudiences: [29, 30, 32],
    attributes: [1, 2, 3, 4, 5, 9, 10, 11, 14, 15, 16, 17, 18, 22, 24, 25, 29, 30, 32],
    domainLists: [29, 30, 32]
  },
  TableTabs: {
    // empty = valid for all channels
    // negative = NOT
    budget: [],
    campaign: [],
    adGroup: [-23], // all but local services
    criterion: [-2], // all but facebook
    keyword: [1, 25], // only search and pinterest
    creative: [-23], // all but local services
    extension: [1, 22], // only search
    form: [2] // only facebook
  }
}
